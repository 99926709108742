import {ContactTab} from './../contact/contact-tab';
import {Contact} from './../matters/shared/contact';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {Tab} from '../shared/tabbing';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {DialogService} from '../shared/dialog/dialog.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {TabType} from '../shared/tabbing/tab';
import {TabFactory} from '../shared/tabbing/tab-factory';
import {MatterTab} from '../matters/matter-tab';
import {ContactQueryService} from '../contact/contact-query.service';
import {ContactCommandService} from '../contact/contact-command.service';
import {ErrorService} from '../shared/error-handling/error-service';
import {ContactService} from '../shared-main/contact.service';
import {MatterParticipantWrapper} from '../matters/shared/matter-participant-wrapper';
import {AdminTab} from '../admin/admin-tab';
import {ThemeStyle} from '../shared-main/constants';
import {GlobalLogger, LogLevelTypes} from './global-logger';
import {currentMatter} from '../matters/shared/current-matter';
import {ProjectTab} from '../projects/shared/project-tab';
import {Project} from '../projects/shared/project';
import {Matter} from '../matters/shared/matter';
import {MatterService} from '../matters';
import {MatterParticipant} from '../matters/shared/matter-participant';
import {FieldCodeHarvestUtil} from '../shared-main/field-codes/field-code-harvest-util';
import {LockScreenService} from './lock-screen.service';
import {FieldMetaData} from '../matters/shared/field-code-mapping';
import {MassUpdateData, MassUpdateTab} from '../shared/tabbing/mass-update-tab';
import {ProjectDocSharingNotifierService} from '../projects/shared/project-doc-sharing-notifier.service';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../matters/shared/matter-participant-role-types';
import {MatterPollingService} from '../../app/core/matter-polling.service';
import {OpportunityMatterTab} from '../opportunity-matter/opportunity-matter-tab';
import {EventTab} from '../event/event-tab';

/**
 * Service encapsulating behaviour and state for a set of tabs. This controls the list of tabs used by a tabs component and
 * it's injected into any component that needs to open new tabs. TabsComponent uses the state in this service to display the tabs
 * and subscribes to state changes to update its model (and trigger UI updates).
 */
@Injectable()
export class TabsService {
    constructor(private router : Router,
                private dialogService : DialogService,
                public errorService : ErrorService,
                private contactService : ContactService,
                private contactQueryService : ContactQueryService,
                private globalLogger : GlobalLogger,
                private contactCommandService : ContactCommandService ,
                public lockScreenService : LockScreenService,
                private projectDocSharingNotifierService: ProjectDocSharingNotifierService , public matterPollingService : MatterPollingService) {

    }

    private static MAX_TABS_NUMBER : number = 10; //TODO: this should be retrieved from a config service

    /**
     * This service is injected into components that need to open tabs. Its state is used by the enclosing tabs component
     * to display the list of tabs and highlight the selected one. The parent tabs component is keeping track of the tabs state by
     * subscribing to this observable
     * @type {BehaviorSubject<Tab[]>}
     */
    public tabsState : Subject<Tab[]> = new BehaviorSubject<Tab[]>(null);

    public tabsCache : Tab[];

    tabsCacheJsonStr : any;

    public themeStyle: ThemeStyle = 'dp-light-grey';


    getMaxTabValue() : number {
        return TabsService.MAX_TABS_NUMBER;
    }


    broadcastChange(tabs : Tab[]) : void {
        this.tabsState.next(tabs);
    }


    get openTabs() : Tab[] {
        if(!this.tabsCache) {
            this.loadOpenTabs();
        }
        return this.tabsCache;
    }

    /**
     * Load the open tabs state from the session storage
     * @returns {Tab[]}
     */
    loadOpenTabs() : void {

        let openTabs : string = sessionStorage.getItem(SESSION_STORAGE_KEYS.openTabs);
        let tabs : Tab[] = [];
        if(openTabs) {
            let jsonTabs : Tab[] = JSON.parse(openTabs);

            for(let json of jsonTabs) {
                tabs.push(TabFactory.createTab(json));
            }
        }
        this.tabsCache = tabs;
        if(tabs && tabs.length > 0) {
            this.setErrorStateFromActiveTab(this.activeTab);
        }

    }


    saveOpenTabs(tabs? : Tab[]) : void {
        if(tabs) {
            this.tabsCache = tabs;
        }

        this.broadcastChange(this.tabsCache);
        //Async call to store tabs in session storage
            this.saveTabsToSessionStorage(this.tabsCache).then();
    }

    replacer(key, val) : any {
        if(key !== "matterComponent") {
            return val;
        }
    }

    saveTabsToSessionStorageForMatter() : void {
        //sessionStorage.setItem(SESSION_STORAGE_KEYS.openTabs, JSON.stringify(this.tabsCache,this.replacer));
    }

    saveTabsToSessionStorage(tabs : Tab[]) : Promise<boolean> {
        let that = this;
        return new Promise(function(resolve, reject) {
            // we are de coupling any component which tabs might be having before stringify tabs
            let tabList : Tab[] = [];
            if(tabs) {
                for(let tab of tabs) {
                    tabList.push(TabFactory.createTab(tab));
                }
            }
            tabList.forEach(t => t.deCoupleUIComponent());
            sessionStorage.setItem(SESSION_STORAGE_KEYS.openTabs, JSON.stringify(tabList));
            resolve(true);
        })
    }


    private setErrorStateFromActiveTab(tab : Tab) {
        if(!tab.dpFooterNotifications || (tab.dpFooterNotifications && tab.dpFooterNotifications.length  == 0)) {
            tab.createDPNotificationFooter();
        }
        this.errorService.closeFooterNotification();
        this.errorService.dpFooterNotifications = tab.dpFooterNotifications;
        this.startMatterPolling(tab);
    }

    startMatterPolling(tab? : Tab) : void {
        let activeTab = tab ? tab : this.activeTab
        if(activeTab.isMatter()){
            this.matterPollingService.startMatterPolling((activeTab as MatterTab) , this.openTabs);
        }
        else{
            this.matterPollingService.stopAllMatterPolling(this.openTabs);
        }
    }


    /**
     * Open an existing tab if already present or add a new one if it's not already in the list. The specified tab will
     * also be marked as the active one.
     * @param tab
     */
    openTab(tab : Tab , queryParams? : any) : void {

         if(this.massUpdateTab && !tab.isMassUpdateSubType()){
            this.dialogService.confirm('Information','To proceed, you need to exit mass update', true)
        }
        else {
            let openTabs : Tab[] = this.openTabs;
            let match: Tab = openTabs.find((t: Tab) => {
                return t.equals(tab);
            });

            // if you have match and that match is also currently active then no need to change anything
            // as you might trying to open tab which is already active`

            if (!match || !(match && match.isActive())) {

                if (match) {

                    /*let index : number = _.findIndex(openTabs, (opentab : any) => opentab == match);
                     if(match.tabType == 'admin' && index === 0) {
                     this.clearAllTabs();
                     this.openTabs.push(match);
                     }*/
                    openTabs.forEach(t => t.setActive(false));
                    match.setActive(true);
                    this.setErrorStateFromActiveTab(match);
                } else {
                    if (openTabs.length > TabsService.MAX_TABS_NUMBER) { //TODO: check the max logic here, this will allow MAX+1
                        return this.showMaxNoOfTabsError();
                    } else {
                        openTabs.forEach(t => t.setActive(false));
                        tab.setActive(true);
                        if (tab.isAnchorTab()) {
                            openTabs.unshift(tab);
                        } else {
                            openTabs.push(tab);
                        }

                        this.setErrorStateFromActiveTab(tab);
                    }
                }

                this.saveOpenTabs();
                //TODO: we should clean this up a bit, not sure if the navigation should stay here
                if (queryParams) {
                    this.router.navigate(tab.routeParams, {queryParams: queryParams});
                }
                else {
                    if(match && match.isWizard()){
                        this.router.navigate(match.routeParams);
                    }else if(match && match.isEvents()){
                        this.router.navigate(match.routeParams, {queryParams: match && (match as EventTab).queryParams});
                    }
                    else{
                        this.router.navigate(tab.routeParams);
                    }
                }
            }
        }


    }

    public isAnyMatterOpenedInTab(matterIds : number[]) : boolean {
        let matterIdsInTab: number[] = this.openTabs.filter(t => t.isMatter() && !t.isAnchorTab()).map(t => (t as MatterTab).matter.id);
        return _.intersection(matterIds, matterIdsInTab).length > 0;
    }

    public isMatterTabOpen(matterId : number) : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.id == matterId && (t.type == 'OPPORTUNITY' ? t.tabType == "opportunityMatter" : t.tabType == 'matter');
        });
    }

    public isProjectTabOpen(projectId : number) : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.id == projectId && t.tabType == 'project';
        });
    }

    public isEventTabOpen(eventId : number) : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.id == eventId && t.tabType == 'event';
        });
    }

    public isContactTabOpen(contactId : number) : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.id == contactId && t.tabType == 'contact';
        });
    }

    public isAdminTabOpen() : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.tabType == 'admin';
        });
    }

    public isBillingTabOpen() : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.tabType == 'billing';
        });
    }

    public isIntegrationsTabOpen() : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.tabType == 'integrations';
        });
    }

    public isMortgageInstrucingTabOpen() : boolean {
        return !!this.openTabs.find((t : Tab) => {
            return t.tabType == 'Mortgage Instructing';
        });
    }


    public getTab(id : number, tabType : TabType) : Tab {
        return this.openTabs.find((t : Tab) => t.id === id && t.tabType === tabType);
    }

    public getGeneralTab(tabType : TabType) : Tab {
        return this.openTabs.find((t : Tab) => t.tabType === tabType);
    }

    get activeTab() : Tab {
        return this.openTabs.find((t : Tab) => t.isActive());
    }

    closeMassUpdateTab(tab : MassUpdateTab, matterService: MatterService) : void {
        this.dialogService.confirm('Confirm',
            'Do you want to exit mass update', false, 'Ok').subscribe(res => {
            if (res) {
                if(tab && tab.isMatter() && tab.isMassUpdateSubType()){

                    this.releaseLockFromMatterParticipants((tab as MatterTab).matter);
                }
               this.removeMassUpdateTab(tab, matterService);
            }
        });
    }

    removeMassUpdateTab(tab : MassUpdateTab, matterService: MatterService) : void {
        if(tab.massUpdateTransaction && tab.massUpdateTransaction.getRemainingMattersCountIncludingFailed() > 0) {
            let remainingMatters = tab.massUpdateTransaction.getRemainingMattersIncludingFailed();
            if(remainingMatters && remainingMatters.length){
                //Unlock all remaining matters and all linked purchase matters
                matterService.unlockMatters(remainingMatters).subscribe();
            }
        }
        if(tab.matter && tab.matter.id > 0){
            //Unlock Precedent Matter
            matterService.unlockMatter(tab.matter.id).subscribe();
            if(tab.matter.matterLink && tab.matter.matterLink.linkedMatterId){
                //Unlock Linked Matter
                matterService.unlockMatter(tab.matter.matterLink.linkedMatterId).subscribe();
            }
        }
        this.removeTabAndActivateAnchorTab(tab)
    }

    releaseLockFromMatterParticipants(matter: Matter): void {
        if (matter && matter.matterParticipants && matter.matterParticipants.length > 0) {
            matter.matterParticipants.forEach(mp => {
                if (mp.sourceContactLockAcquired) {
                    this.contactQueryService.unlockContact(mp.contact.sourceContactId);
                }
            })
        }
    }
    removeTabAndActivateAnchorTab(tab : Tab) : void {
        this.removeTab(tab);
        this.openAnchorTab();
    }

    openAnchorTab(): void {
        if(this.openTabs.length > 0){
            this.openTab(this.openTabs[0]);
        }
    }

    removeContactTabWithoutDirtyCheck(tab : Tab) : void {

        if(tab.active == "active") {
            let lastTab = this.openTabs.length - 1;
            // get the index of the tab
            let indexofTab : number = _.findIndex(this.openTabs, (chr : any) => chr == tab);
            //// if you close the last one in the list and it's active
            if(indexofTab == lastTab) {
                this.removeTab(tab);
                this.openTab(this.openTabs[lastTab - 1]);
            } else {
                this.removeTab(tab);
                this.openTab(this.openTabs[indexofTab]);
            }
        } else {
            this.removeTab(tab);
        }


    }


    removeTab(tab : Tab) : void {

        let openTabs : Tab[] = this.openTabs;
        let index : number = _.findIndex(openTabs, (chr : any) => chr == tab);
        if(tab.isMatter() && (tab as MatterTab).matter){
            (tab as MatterTab).removeDocuSignInProgressPolling();
        }
        openTabs.splice(index, 1);
        this.saveOpenTabs();

    }

    showContactTabDirtyDialog(tab : Tab) : Observable<any> {

        return this.dialogService
                   .confirmUnsavedChange(true)
                   .map((response : any) => {
                       return response;
                   });
    }

    showDirtyDialog(message : string) : Observable<any> {

        return this.dialogService
                   .confirmUnsavedChange(true, message)
                   .map((response : any) => {
                       return response;
                   });
    }

    showMaxNoOfTabsError(customInOrderToTxt? : string) {

        // alert('A maximum of 10 tabs can be opened. In order to open another, a tab must first be closed.'); //Temp solution, not sure if
        // this should be here in the first place
        let inOrderToText = customInOrderToTxt ? customInOrderToTxt : 'In order to open another';
        this.dialogService.confirm('Information',
            `A maximum of ${TabsService.MAX_TABS_NUMBER} tabs can be opened.<br> ${inOrderToText}, a tab must first be closed.`, true);
    }

    tabExists(id : number) : boolean {

        let exists : boolean = _.some(this.openTabs, (tab : Tab) => {
            return tab.id === +id;
        });

        return exists;
    }

    anyTabExists() : boolean {

        let openTabs : Tab[] = this.openTabs;

        return openTabs && openTabs.length > 0;
    }

    anyTabExistsByType(tabType : string) : boolean {

        let tabsByType : Tab[] = _.filter(this.openTabs, tabObj => tabObj.tabType === tabType);

        return tabsByType && tabsByType.length > 0;
    }

    removeAnchorTab() : void {
        let tab = this.openTabs.find(item => item.isAnchorTab());
        if(tab) {
            this.removeTab(tab);
        }

    }

    get anchorTab() : Tab {
        return (this.openTabs && this.openTabs.find(item => item.isAnchorTab()));
    }

    get massUpdateTab() : Tab {
        return (this.openTabs && this.openTabs.find(item => item.isMassUpdateSubType()));
    }

    clearAllTabs() : void {
        this.tabsCache = [];
        this.saveOpenTabs();
    }

    activateAppRoute(adminRoute : any[], landing? : boolean , extraQueryParams? : any) : void {
        let queryParamsObj =  {landing: landing}
        if(extraQueryParams){
            queryParamsObj = extraQueryParams
        }
        if (this.openTabs && this.anchorTab && this.openTabs.length > 1) {
            if (this.anchorTab.isAccountAdmin() || this.anchorTab.isAdmin()) {
                this.dialogService.confirm('Information', 'To proceed, all active tabs must first be closed.', true);
                return;
            } else if (this.openTabs.some( tabPrjct => tabPrjct instanceof ProjectTab)) {
                this.checkForProjectNotifierMessages(() => {
                    this.router.navigate(adminRoute, {queryParams:queryParamsObj});
                });
            }
        } else if (this.massUpdateTab) {
            this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
            return;
        }
        this.router.navigate(adminRoute, {queryParams: queryParamsObj});
    }

    checkForProjectNotifierMessages(callBack: Function) {
        const prjctTab: ProjectTab = <ProjectTab>this.openTabs.find(tabPrjct => tabPrjct instanceof ProjectTab && (!!((tabPrjct as ProjectTab).showSharingNotifier)));
        if (prjctTab && prjctTab.showSharingNotifier && prjctTab.project) {
            // not good enough
            // this.dialogService.confirm('Information', `To proceed, "${prjctTab.project.projectRecordNumber}" project must be closed.`, true);
            // return;

            // if moving away from a project that has a pending notifier, open notifier modal
            this.projectDocSharingNotifierService.showProjectDocSharingNotifierDialog(prjctTab).subscribe(() => {
                if (callBack) {
                    callBack();
                }
            });
        } else {
            if (callBack) {
                callBack();
            }
        }
    }

    activateAdminAppRoute(adminRoute : any[], landing? : boolean) : void {
        if(this.openTabs.length > 1) {
            this.dialogService.confirm('Information', 'To proceed, all active tabs must first be closed.', true);
        }
        else {
            this.router.navigate(adminRoute, {queryParams : {landing : landing}});
        }
    }

    /**
     * Check if the source contact for this snapshot it already locked somewhere else in the user's session.
     * The locking mechanism will report success if the lock is acquired by the same user (TODO: we might want to change this with the new editing approach)
     * so we need to check the current session to see if the user is already working with this (i.e. acquired lock for it) in another matter or contact tab
     * @param {Contact} contact
     */
    isContactAlreadyLocked(contact : Contact) {
        const openTabs : Tab[] = this.openTabs
        if(Array.isArray(openTabs)) {
            for(var i = 0; i < openTabs.length; i++) {
                var tab : Tab = openTabs[i];
                if(tab.isMatter()&& tab instanceof  MatterTab && (tab as MatterTab).matter) {
                    let matterParticipants = (tab as MatterTab).matter && (tab as MatterTab).matter.matterParticipants;
                    if(Array.isArray(matterParticipants)) {
                        for(var j = 0; j < matterParticipants.length; j++) {
                            var participant = matterParticipants[j];
                            if(participant.contact.sourceContactId == contact.sourceContactId && participant.sourceContactLockAcquired) {
                                console.log("contact.sourceContactId "+contact.sourceContactId+" locked by "+(tab as MatterTab).matter.id);
                                return true;
                            }
                        }
                    }
                } else if(tab.isContact()&& tab instanceof  ContactTab && (tab as ContactTab).contact) {
                    //If open, the contact would be
                    // open for editing (locked acquired by this user) if we get here. If contact were locked by somebody else, we
                    // would not get an OK for locking from a matter contact edit. Border case: the contact is open in read only
                    // (previous use locked it) and the lock is released before user edits in another matter but contact tab is not
                    // refreshed
                    if((tab as ContactTab).contact.id == contact.sourceContactId) {
                        return true;
                    }
                }
            }
        }

        return false;

        /*!!this.tabsService.openTabs.find(tab =>
         tab.isMatter() && !!((tab as MatterTab).matter.matterParticipants.find(participant =>
         participant.contact.sourceContactId == contact.sourceContactId &&
         participant.sourceContactLockAcquired))
         ||
         tab.isContact() && (tab as ContactTab).contact.id == contact.sourceContactId //If open, the contact would be
         // open for editing (locked acquired by this user) if we get here. If contact were locked by somebody else, we
         // would not get an OK for locking from a matter contact edit. Border case: the contact is open in read only
         // (previous use locked it) and the lock is released before user edits in another matter but contact tab is not
         // refreshed
         );*/
    }

    logLockMsg(matterParticipantWrapper : MatterParticipantWrapper, locked : boolean) {
        if(matterParticipantWrapper && matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.contact) {
            let contactName : string = matterParticipantWrapper.matterParticipant.contact.organizationName
                ? matterParticipantWrapper.matterParticipant.contact.organizationName
                : matterParticipantWrapper.matterParticipant.contact.fullName;

            let logMsg = "Getting" + (locked? " not" : "")  +  " lock for matterId : "+(currentMatter.value && currentMatter.value.id)
                + " , matterParticipantRole : " + matterParticipantWrapper.matterParticipant.matterParticipantRole
                + " , contactId : " + matterParticipantWrapper.matterParticipant.contact.id
                + " , sourceContactId : " + matterParticipantWrapper.matterParticipant.contact.sourceContactId
                + " , contact name : " + contactName;
            this.globalLogger.log(LogLevelTypes.DEBUG, logMsg);
        }
    }

    logUnLockMsg(matterParticipantWrapper : MatterParticipantWrapper) {
        if(matterParticipantWrapper && matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.contact) {
            let contactName : string = matterParticipantWrapper.matterParticipant.contact.organizationName
                ? matterParticipantWrapper.matterParticipant.contact.organizationName
                : matterParticipantWrapper.matterParticipant.contact.fullName;

            let logMsg = "Unlock for request for wrapper matter matterId: "+(currentMatter.value && currentMatter.value.id)
                + " , matterParticipantRole : " + matterParticipantWrapper.matterParticipant.matterParticipantRole
                + " , contactId : " + matterParticipantWrapper.matterParticipant.contact.id
                + " , sourceContactId : " + matterParticipantWrapper.matterParticipant.contact.sourceContactId
                + " , contact name : " + contactName;
            this.globalLogger.log(LogLevelTypes.DEBUG, logMsg);
        }
    }

    hideContactNotFoundError(matterParticipantRole: MatterParticipantRole): boolean {
        return matterParticipantRole == MatterParticipantRoleTypes.OTHERPARTY_LAW_FIRM
            || matterParticipantRole == MatterParticipantRoleTypes.OTHERPARTY_SOLICITOR;
    }

    //This method is doing two things, locks the source contact and return updated data for it. Therefore even if lock is not required it's still fetching
    // the source contact from backend.
    lockSourceContactForShutter(matterParticipantWrapper : MatterParticipantWrapper, isLock : boolean) : Observable<Contact> {
        if(matterParticipantWrapper && matterParticipantWrapper.matterParticipant) {
            if(isLock) {
                console.log("Getting lock for "+matterParticipantWrapper.matterParticipant.matterId);
                if(!matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
                    console.log("Getting lock for "+matterParticipantWrapper.matterParticipant.matterId+" ," +
                         +matterParticipantWrapper.matterParticipant.contact.id);

                    //We defer the opening until we get the result for the locking check and open in the proper way
                    return this.contactQueryService.getContact(matterParticipantWrapper.matterParticipant.contact.sourceContactId, matterParticipantWrapper.matterParticipant.contact.displayName).map(
                        (res : Contact) => {
                            console.log("For wrapper matter "+matterParticipantWrapper.matterParticipant.matterId+" , is contact already locked "+res.locked);
                            this.logLockMsg(matterParticipantWrapper, res.locked);
                            if(!res.locked) {
                                console.log("lock got successfully For wrapper matter "+matterParticipantWrapper.matterParticipant.matterId)
                                matterParticipantWrapper.lockedSourceContact = null;
                                matterParticipantWrapper.isLockedElsewhere = false;
                                matterParticipantWrapper.matterParticipant.sourceContactLockAcquired = true;
                                console.log("lock was acquired successfully For wrapper matter "+matterParticipantWrapper.matterParticipant.matterId+" and" +
                                    " matterParticipantWrapper.obsolete " + matterParticipantWrapper.obsolete);
                            } else {
                                console.log("lock attempt not successful for wrapper matter " + matterParticipantWrapper.matterParticipant.matterId)
                                matterParticipantWrapper.lockedSourceContact = res;
                                matterParticipantWrapper.isLockedElsewhere = true;
                                matterParticipantWrapper.matterParticipant.sourceContactLockAcquired = false;
                            }
                            return res;
                        }
                    );
                } else {
                    return this.contactQueryService.getContactForMatter(matterParticipantWrapper.matterParticipant
                                                                        && matterParticipantWrapper.matterParticipant.contact
                                                                        && matterParticipantWrapper.matterParticipant.contact.sourceContactId,
                                                                        matterParticipantWrapper.matterParticipant.contact.displayName,
                                                                        this.hideContactNotFoundError(matterParticipantWrapper.matterParticipant.matterParticipantRole));
                }
            } else {
                console.log("Unlock for request for wrapper matter "+matterParticipantWrapper.matterParticipant.matterId+" , contact lock status is "+matterParticipantWrapper.matterParticipant.sourceContactLockAcquired
                    +" , and contact dirty status is  "+matterParticipantWrapper.matterParticipant.contact.isDirty);
                //Closing, we try to clear the locking data
                if(matterParticipantWrapper.matterParticipant.sourceContactLockAcquired && !matterParticipantWrapper.matterParticipant.contact.isDirty) {
                    //Making contact readOnly even before sending the unlock request. As backend might take some time to
                    // unlock it but from UI's perspective contact should be shown unlocked & read-only when action is triggered. If request
                    // fails then user has to just acquire the lock again on the same contact
                    matterParticipantWrapper.resetOnUnlock();
                    console.log("Flag got reset for wrapper matter "+matterParticipantWrapper.matterParticipant.matterId+" , lock status is "+matterParticipantWrapper.matterParticipant.sourceContactLockAcquired+" , and contact dirty status is "+matterParticipantWrapper.matterParticipant.contact.isDirty);
                    this.logUnLockMsg(matterParticipantWrapper);
                    return this.contactQueryService.unlockContact(matterParticipantWrapper.matterParticipant.contact.sourceContactId).map(
                        (res : Contact) => {

                            return res;
                        });

                } else {
                    return this.contactQueryService.getContactForMatter(matterParticipantWrapper.matterParticipant
                                                                        && matterParticipantWrapper.matterParticipant.contact
                                                                        && matterParticipantWrapper.matterParticipant.contact.sourceContactId);
                }

                //Resetting this locked info, it will be refetched next time we open
                // matterParticipantWrapper.lockedSourceContact = null;
                // return Observable.of(null);
            }
        }
        return Observable.of(null);
    }

    unLockSourceContactForParticipant(matterParticipant : MatterParticipant) {
        if(matterParticipant && matterParticipant.contact) {
            this.contactQueryService.unlockContact(matterParticipant.contact.sourceContactId).subscribe();
            matterParticipant.sourceContactLockAcquired = false;
        }
    }

    unLockSourceContact(matterParticipantWrapper : MatterParticipantWrapper) {
        if(matterParticipantWrapper && matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.contact) {
            this.unLockSourceContactForParticipant(matterParticipantWrapper.matterParticipant);
            matterParticipantWrapper.lockedSourceContact = null;
        }
    }

    updateIsLockedElsewhereStatus(wrapper : MatterParticipantWrapper, sourceContact : Contact) : void {
        if(wrapper && wrapper.matterParticipant && wrapper.matterParticipant.contact) {
            if(wrapper.matterParticipant.sourceContactLockAcquired) { //Check if it is locked by itself
                wrapper.isLockedElsewhere = false;
                wrapper.lockedSourceContact = null;
            } else { //Check if it is locked by other user or same user different tabs(matter or contact tab)
                wrapper.isLockedElsewhere = sourceContact.locked
                    || this.isContactAlreadyLocked(wrapper.matterParticipant.contact);
                if(wrapper.isLockedElsewhere) {
                    wrapper.lockedSourceContact = sourceContact;
                } else {
                    wrapper.lockedSourceContact = null;
                }
            }
        }
    }


    isSystemAccountSelected() : boolean {
        let currentTab : AdminTab = this.activeTab as AdminTab;
        const selectedActId = currentTab.account && currentTab.account.id ? currentTab.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        const loggedInActId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

        return selectedActId === loggedInActId;
    }

    getMatterTab(matterId : number) : MatterTab {
        return this.openTabs.find(tab => tab instanceof MatterTab && tab.matter && tab.matter.id == matterId) as MatterTab;
    }

    getOpportunityMatterTab(matterId : number) : OpportunityMatterTab {
        return this.openTabs.find(tab => tab instanceof OpportunityMatterTab && tab.matter && tab.matter.id == matterId) as OpportunityMatterTab;
    }

    isLinkedMatterDirty(tab? : Tab) : boolean {
        let currentTab = tab ? tab : this.activeTab;
        if(currentTab && currentTab.isMatter() && (currentTab as MatterTab).linkedMatter
        && (currentTab as MatterTab).linkedMatter.id){
            let matterTab = this.getMatterTab((currentTab as MatterTab).linkedMatter.id);
            if(matterTab){
              return  matterTab.matter.dirty
            }
        }
        return false;
    }

    isLinkedMatterTabOpen(id) : boolean {
       return this.openTabs ? this.openTabs.some( item => item.isMatter() && (item as MatterTab).linkedMatter && (item as MatterTab).linkedMatter.id == id) : false;
    }

    canOpenTab() : boolean {
        let openTabs : Tab[] = this.openTabs;
        if(openTabs.length > TabsService.MAX_TABS_NUMBER) { //TODO: check the max logic here, this will allow MAX+1
            this.showMaxNoOfTabsError();
            return false;
        } else {
            return true;
        }
    }

    isMassUpdatTabOpen() : boolean {
        return this.openTabs && this.openTabs.some(item => item.isMassUpdateSubType());
    }

    getProjectFromOpenTabs(projectId: number) : Project {
        let tab : ProjectTab = this.getProjectTab(projectId);
        return tab && tab.project;
    }

    getProjectTab(projectId: number) : ProjectTab {
        return this.getTab(projectId, "project") as ProjectTab;
    }

    isMatterProjectLocked(matter) : boolean {
        let projectTab = matter && this.getProjectTab(matter.unityProjectId);
        if(projectTab && projectTab.projectMatters ){
            let matterIndex = projectTab.projectMatters.findIndex(matter => matter.id == matter.id);
            return matterIndex != -1;
        } else {
            return false;
        }
    }

    isAnyProjectMatterOpen(projectId: number) : boolean {
        let tab : MatterTab = this.openTabs.find((tab: MatterTab)=>{
            return tab && tab.matter && tab.matter.unityProjectId == projectId;
        }) as MatterTab;
        return !!tab;
    }

    async saveMatterAndClosetab(tab: MatterTab, tabs: Tab[], isTabReadOnly: boolean, removeTabFlag: boolean = true,  matterService: MatterService):Promise<boolean>{
        if (tab.isDirty() && !tab.isLocked() && !isTabReadOnly) {
            this.openTab(tab);
            let response = await this.showContactTabDirtyDialog(tab).toPromise();
            if (response) {
                if (response == "DONT_SAVE") {
                    this.unlockMatter(tab, matterService);
                    this.releaseLockFromMatterParticipants(tab.matter);
                    if(removeTabFlag){
                        this.removeTabAndOpenNextTab(tab, tabs);
                    }
                    return Promise.resolve(true);

                } else if (response == "SAVE") {
                    if(tab.matterComponent){
                        let result = await tab.matterComponent.validateAndSaveMatter().toPromise();
                        if (result) {
                            this.unlockMatter(tab, matterService);
                            if(removeTabFlag){
                                setTimeout(()=>{
                                    this.removeTabAndOpenNextTab(tab, tabs);
                                },0);

                            }
                            return Promise.resolve(true);
                        } else {
                            return Promise.resolve(false);
                        }
                    }

                } else {
                    return Promise.resolve(false);
                }
            }

        }
        else {
            this.unlockMatter(tab, matterService);
            if(removeTabFlag){
                this.removeTabAndOpenNextTab(tab, tabs);
            }
            return Promise.resolve(true);

        }
    }

    async removeWizardTab(tab: MatterTab, tabs: Tab[], isTabReadOnly: boolean, removeTabFlag: boolean = true,  matterService: MatterService) : Promise<boolean> {
        return this.saveMatterAndClosetab(tab,tabs,isTabReadOnly,removeTabFlag,matterService);
    }

    async removeMatterTab(tab: MatterTab, tabs: Tab[], isTabReadOnly: boolean, removeTabFlag: boolean = true,  matterService: MatterService) : Promise<boolean> {
        return this.saveMatterAndClosetab(tab,tabs,isTabReadOnly,removeTabFlag,matterService);
    }

    unlockMatter(tab : MatterTab, matterService: any) : void {

        if(tab.isOpportunityMatter() && this.anyNewMatterFromOpportunityExist(tab.matter.id)){
            //Don' unlock opportunity matter if there is still a non saved converted matter open
            return;
        }
        if(!this.isMatterProjectLocked(tab.matter)){
            if(tab && tab.linkedMatter && tab.matter && tab.matter.lockedByLoggedInUser){
                let matterTab  = this.getMatterTab(tab.linkedMatter.id);
                // If we have linked Matter open then we do not release lock on current mattter since linked matter is open already
                if(!matterTab){
                    matterService.unlockMatter(tab.id).subscribe((res) => {
                    });
                    matterService.unlockMatter(tab.linkedMatter.id).subscribe((res) => {
                    });
                }
            }
            else if(tab && tab.matter && tab.matter.lockedByLoggedInUser){
                let stackTrace: string = JSON.stringify(new Error().stack);
                matterService.unlockMatter(tab.id).subscribe((res) => {
                    },
                    (error) =>
                    {
                        if (error && error.errorCode && error.errorCode === 'app.alreadyUnlockedMatter') {
                            this.globalLogger.log(LogLevelTypes.DEBUG,`Attempt to unlock a matter that was not locked ${stackTrace}`);
                        }
                    });
                if(tab.matter.project && tab.matter.project.templateMatterId) {
                    matterService.unlockMatter(tab.matter.project.templateMatterId).subscribe((res) => {
                    });
                }
            }
        }

    }

    public removeTabAndOpenNextTab(tab: Tab, tabs: Tab[]) {
        if (tab.active == "active") {
            let lastTab = tabs.length - 1;
            // get the index of the tab
            let indexofTab: number = _.findIndex(tabs, (chr: any) => chr == tab);
            //// if you close the last one in the list and it's active
            if (indexofTab == lastTab) {
                this.removeTab(tab);
                this.openTab(tabs[lastTab - 1]);
            } else {
                this.removeTab(tab);
                this.openTab(tabs[indexofTab]);
            }
        } else {
            this.removeTab(tab);
        }


    }

    releaseContactLock(matterParticipant :MatterParticipant): void{
        //If there is not sourceContactId, don't need release lock
        if(matterParticipant  && matterParticipant.contact && matterParticipant.contact.sourceContactId && matterParticipant.sourceContactLockAcquired) {
           this.unLockSourceContactForParticipant(matterParticipant);
        }
    }

    releaseContactLockIfNotDirty(matterParticipant :MatterParticipant): void{
        //If there is not sourceContactId, don't need release lock
        if(matterParticipant  && matterParticipant.contact && matterParticipant.contact.sourceContactId && matterParticipant.sourceContactLockAcquired
            && !matterParticipant.contact.isDirty) {
            this.unLockSourceContactForParticipant(matterParticipant);
        }
    }

    setLastFocusedElementMetaData(element : any) : void {
        if(this.activeTab && this.activeTab.isMatter()){
            this.activeTab.lastFocusedElementMetaData = this.generateFieldMetaData(element)
        }
    }

    generateFieldMetaData(element : any) : FieldMetaData {
        let fieldCodeHarvestUtil = new FieldCodeHarvestUtil();
        return  fieldCodeHarvestUtil.generateFieldMetaData(element , this.router.url);
    }


    isTabAlreadyOpened(tabType : TabType, type: String) : boolean {
        let openedTabs : Tab[] = _.filter(this.openTabs, tabObj => tabObj.tabType === tabType && tabObj.type == type);
        return openedTabs && openedTabs.length > 0;
    }

    isContactListTabAlreadyOpened(){
        return this.isTabAlreadyOpened('contact', 'Contacts');
    }

    isMatterListTabAlreadyOpened(){
        return this.isTabAlreadyOpened('matter', 'Matters');
    }

    isHomeTabAlreadyOpened(){
        return this.isTabAlreadyOpened('home', 'Home');
    }

    get massUpdateData(): MassUpdateData {
        let massUpdateTab: MassUpdateTab = this.activeTab && (this.activeTab as MassUpdateTab);
        if (massUpdateTab) {
            if (!massUpdateTab.massUpdateData) {
                massUpdateTab.massUpdateData = new MassUpdateData();
            }
            return massUpdateTab.massUpdateData;
        }
        return null;
    }

    anyNewMatterFromOpportunityExist(opportunityMatterId: number) : boolean {
        return this.openTabs.some(tab=>tab.isMatter()
            && (<MatterTab>tab).matter
            && (<MatterTab>tab).matter.sourceMatterId == opportunityMatterId
            && (<MatterTab>tab).matter.isNewMatter());
    }

}

