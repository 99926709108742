import moment, {Moment} from "moment";
import {SoaRealtyTaxAdjustmentUtil} from "../realty-tax/soa-realty-tax-adjustment-util";
import {Matter} from "../../../shared";
import Utils from "../../../../shared-main/utils";

export class TaxesPaidDuringOccupancyUtil {
  static calculateDateDiffForTaxPortion(matter: Matter): string {
    // using final closing date becuase this is for a final adjustment only
    let closingDate: string = matter.getFinalClosingDate();
    let occupancyDate: string = this.getOccupancyDate(matter);
    if (
      closingDate &&
      occupancyDate &&
      moment(closingDate, "YYYY/MM/DD") > moment(occupancyDate, "YYYY/MM/DD")
    ) {
      let numofDays: number = moment(closingDate, "YYYY/MM/DD").diff(
        moment(occupancyDate, "YYYY/MM/DD"),
        "days",
      );
      return String(numofDays);
    } else {
      return "???";
    }
  }

  static calculateDateDiffToEndOfYearForTaxPortion(date: string): number {
    let yearEnd: string = Utils.getYearEndDate(date);
    if (
      moment(yearEnd, "YYYY/MM/DD") >
      moment(date, "YYYY/MM/DD")
    ) {
      return moment(yearEnd, "YYYY/MM/DD").diff(
        moment(date, "YYYY/MM/DD"),
        "days",
      ) + 1;
    } else {
      return 0;
    }
  }

  static calculateDateDiffFromStartOfYearForTaxPortion(date: string): number {
    let yearStart: string = Utils.getYearStartDate(date);
    if (
      moment(date, "YYYY/MM/DD") >
      moment(yearStart, "YYYY/MM/DD")
    ) {
      return moment(date, "YYYY/MM/DD").diff(
        moment(yearStart, "YYYY/MM/DD"),
        "days",
      );
    } else {
      return 0;
    }
  }

  static getOccupancyDate(matter: Matter): string {
    return SoaRealtyTaxAdjustmentUtil.getOccupancyDateForRealtyTax(matter);
  }

  //the taxPortion value is year-based instead of month-based
  static calculateShareForTaxPortion(
    taxPortion: number,
    matter: Matter,
  ): number {
    let closingDate: string = matter.getFinalClosingDate();
    let occupancyDate: string = this.getOccupancyDate(matter);
    let dayBeforeClosing: Moment = moment(closingDate).add(-1, "days");

    let spanInYears = moment(closingDate).year() - moment(occupancyDate).year();

    let closingInLeapYear: boolean = dayBeforeClosing.isLeapYear();
    let occupancyInLeapYear: boolean = Utils.isLeapYear(occupancyDate);

    if (
      this.calculateDateDiffForTaxPortion(matter) == "???" ||
      taxPortion <= 0
    ) {
      return 0;
    }

    if (spanInYears > 0){
      let occupancyBookend: number = this.calculateDateDiffToEndOfYearForTaxPortion(occupancyDate);
      let closingBookend: number = this.calculateDateDiffFromStartOfYearForTaxPortion(closingDate);
      let occupancyRate: number = (1 / (occupancyInLeapYear? 366 : 365)) * taxPortion * occupancyBookend;
      let closingRate: number = (1 / (closingInLeapYear? 366 : 365)) * taxPortion * closingBookend;

      return occupancyRate + (taxPortion * (spanInYears-1)) + closingRate;

    }

    if (!occupancyInLeapYear && !closingInLeapYear) {
      return (
        (1 / 365) *
        taxPortion *
        Number(this.calculateDateDiffForTaxPortion(matter))
      );
    }

    if (occupancyInLeapYear && closingInLeapYear) {
      return (
        (1 / 366) *
        taxPortion *
        Number(this.calculateDateDiffForTaxPortion(matter))
      );
    }
  }
}
