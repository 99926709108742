import {BaseEntity} from '../shared/BaseEntity/base-entity';

export class UnityBilling extends BaseEntity {

    customerAccountId: number;
    id : number;
    warningLevelAmount: number;
    currentBalance: number;
    purchaseTransactionCredits: PurchaseTransactionCredit[];
    fee: number;
}

export class PurchaseCreditsRequest {
    amount: number;
    authorizedBy: string;
}

export class PurchaseTransactionCredit  {
    unityEventId: string;
    requestId: string;
    creditCardNumMasked: string;
    creditCardTransAuthorizationID: string;
    creditCardTransReferenceNum: string;
    requestedAmount: number;
    transactionDate: number;
    convenienceFee: ConvenienceFee;
}

export class ConvenienceFee  {
  fee: number;
  rate: number;
  readableRate: string;
}

export class BillingTransactionReport extends BaseEntity {
    amount: number;
    createdBy: string;
    creditCardNumber: string;
    customerAccountId: number;
    federalTax: number;
    fileNumber: string;
    id: number;
    matterNumber:string;
    orderNumber: string;
    originalOrderNumber: string;
    productId: string;
    productName: string;
    provincialTax: number;
    solicitor: string;
    transactionDate: number;
    transactionDescription: string;
    transactionType: string;
    projectMatter: boolean;
    projectNumber: string;
}
